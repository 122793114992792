/* ____________________________ Generic Classes & Elements ___________________________ */

:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --app-border-radius: 10px;
  --primary-blue: rgb(2, 106, 252);
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-20 * {
  flex: 1 0 20%;
  width: 0;
  /* Fixes width of large items */
}

.flex-33 * {
  flex: 1 0 33%;
  width: 0;
}

.flex-50 * {
  flex: 0 0 49.55%;
  /* Substracts from margin */
  width: 0;
  margin: 0px 10px 10px 0px !important;
}

.flex-50 *:nth-child(2n) {
  margin-right: 0px !important;
  /* To align to navigation button :)*/
}



.column-3 {
  column-count: 3;
  column-gap: 5px;
}

.column-2 {
  column-count: 2;
  column-gap: 32px;

}

.column-2 *,
.column-3 * {
  max-width: 100%;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  overflow: hidden;
}

/*.column-2 span {
      padding: 1px;
    } might also fix the box shadows */

.column-3.gap-15 {
  column-gap: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.w-100 {
  width: 100%;
}

.text-align-center {
  text-align: center;
}

.btn-primary {
  font-size: 0.8rem;
  padding: 10px 30px 10px 30px;
  background-color: var(--primary-blue);
  border-radius: 8px;
  border-width: 0px;
  border-color: rgb(0, 0, 0);
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-align: center;
}

.btn-primary a,
.btn-primary a:link,
.btn-primary a:visited,
.btn-primary a:hover {
  color: #fff;
  text-decoration: none;
}

.btn-secondary {
  font-size: 0.8rem;
  padding: 10px 30px 10px 30px;
  border: 1px solid !important;
  color: var(--primary-blue) !important;
  border-color: var(--primary-blue);
  border-radius: 8px;
  border-width: 0px;
  border-color: rgb(0, 0, 0);
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-align: center;
}

.img-clickable {
  cursor: pointer;
}

.img-arrow {
  display: inline !important;
  margin: 0 !important;
  margin-bottom: 2px !important;
  padding: 0 !important;
  max-width: 16px;
  max-height: 16px;
  vertical-align: middle;
}

.img-profile-home {
  display: initial !important;
  max-width: 350px;
}

/* ________________________________________________________________ */

html {
  font-size: 16px;
  background: url("./images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  user-select: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  cursor: url(./images/cursor.svg), default;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
a:link,
a:visited {
  color: var(--primary-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline 3px solid;
}

h3 {
  font-size: 1rem;
  line-height: 0;
  font-weight: 100;
  letter-spacing: 1px;
  animation: fadeIn 1s;
}

body {
  height: 100vh;
  width: 100%;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

blockquote {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  margin-left: 0;
  animation: fadeIn 1s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  /*background: var(--color-accent-footer);*/
  /* background: var(--color-font-light-grey); */
  background: transparent;
  width: 7px;
  border-radius: 0px var(--app-border-radius) 0px 0px;

}

::-webkit-scrollbar-track {
  margin: 40px;
  background: transparent
}

::-webkit-scrollbar-thumb {
  background-color: #31313190;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 9999px;
  transition: all ease 2.5s;
}

::-webkit-scrollbar-thumb:window-inactive {
  /* background: #555D69; */
  background-color: #9e9e9e70;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #0096FF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.App {
  display: grid;
  align-self: center;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr 50px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "sidebar content"
    "sidebar nav";
  height: 82vh;
  width: 80vw;
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
  border-radius: var(--app-border-radius);
  /*backdrop-filter: blur(5px);*/
  animation: fadeIn;
  animation-duration: 1.5s;
}

.sidebar {
  grid-area: sidebar;
  font-size: 1.1rem;
  font-weight: 100;
  color: rgb(255, 255, 255);
  background: linear-gradient(-45deg, rgba(53, 195, 243, 0.6) 0%, rgba(139, 159, 232, 0.7) 20%, rgba(230, 129, 216, 0.6) 39%, rgba(255, 169, 164, 0.6) 76%, rgba(254, 210, 206, 0.6) 100%);
  /*background: linear-gradient(#21e1ae90, #09aeea90);*/
  background-size: cover;
  border-radius: var(--app-border-radius) 0px 0px var(--app-border-radius);
  text-align: center;
  overflow: hidden;
  padding: 20px;
}

.sidebar h1 {
  font-size: 2.2rem;
  margin: 10px 0 10px 0;
}

.sidebar img {
  transition: 0.25s all ease-in-out;
}

.sidebar img:hover {
  animation: pulse 1s infinite;
}

.sidebar hr {
  border: none;
  border-bottom: 3px dotted rgb(255, 255, 255);
  margin: 20px 0px 20px 0px;
}

.sidebar-photo {
  margin-top: 20px;
}

.sidebar-photo img {
  max-height: calc(50px + 3vw);
  max-width: calc(50px + 3vw);
  border-radius: 100%;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.75);
}

.sidebar-section img {
  max-width: 32px;
  max-height: 32px;
  /*filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.50));*/
}

.sidebar-section img.progress {
  max-width: 200px;
  max-height: 32px;
}

.sidebar-section h2 {
  position: relative;
  display: block;
  font-size: 1.1rem;
  margin: 20px 0px 20px 0px;
  text-transform: uppercase;
  text-align: center;
}

.sidebar-section h2 img {
  position: absolute;
  top: 20%;
  left: 5%;
}

.sidebar-section-social,
.sidebar-section-languages,
.sidebar-section-skills {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}

.sidebar-section-languages {
  padding: 0px 10px;
}

.sidebar-section-languages>* {
  flex-basis: 80%;
}

.sidebar-section-social {
  justify-content: center;
  align-items: flex-start;
}

.sidebar-section-social img {
  max-width: 32px;
  max-height: 32px;
  margin: 5px 8px;
}

.sidebar-section-social span {
  margin-left: 10px;
}


.sidebar-section-skills {
  justify-content: flex-start;
  align-items: center;
}

.sidebar-section-skills img {
  max-width: 72px;
  max-height: 72px;
  width: 40px;
  height: 40px;
  margin: 10px;
  margin-bottom: 5px;
  /*filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));*/
}

.content {
  grid-area: content;
  display: block;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  background: rgba(245, 245, 245, 0.9);
  color: rgb(25, 25, 25);
  border-radius: 0px var(--app-border-radius) 0px 0px;
  padding: 20px 15px 20px 20px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
}

.content p {
  line-height: 150%;
  text-align: justify;
  animation: fadeInLeft 1s;
  hyphens: auto;
}

.content-generic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-generic img {
  max-width: 100%;
  max-height: 90vh;
}

.content h2 {
  display: block;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 20px;
  animation: fadeIn 1s;
}

.content h2 img {
  position: absolute;
  top: 0%;
  left: 0%;
  max-width: 24px;
  max-height: 24px;
}

.content img {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin: 0px 0px 5px 0px;
  animation: backInDown 1s normal forwards;
  transition: 0.5s all ease-in-out !important;
  border-radius: 5px;
  opacity: 0.95;
}

.content img.smartphone {
  max-height: 500px;
  max-width: 40vw !important;
  box-shadow: none;
}

.content img.company-logo {
  position: absolute;
  width: 72px;
  height: auto;
  top: 30px;
  right: 20px;
  box-shadow: none;
}

.content img:hover {
  opacity: 0.8 !important;
}

.content .box {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 350px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 10px;
  line-height: 150%;
  animation: zoomIn;
  animation-duration: 2s;
}

.content .box .title {
  font-size: 1.1rem;
  letter-spacing: -0.5px;
}

.content .box .info {
  font-weight: 600;
  margin-bottom: 10px;
}

.content .box .description {
  padding-bottom: 20px;
}

.content .box .date {
  margin-top: auto;
  /* Hack to justify it at the bottom */
  width: 100%;
  bottom: 20px;
  padding-top: 10px;
  font-style: italic;
  color: #444;
  border-top: 1px solid #d8d8d8;
  line-height: 150%;
}

.content .box ul {
  padding-left: 20px;
  line-height: 1.75rem;
  margin-top: 0;
}


.content-cv .work-experience {
  grid-area: work-experience;
}

.content-cv .side-projects {
  grid-area: side-projects;
}

.content-cv .education {
  grid-area: education;
}

.content-home {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100%;
}

.content-home>div:nth-child(1) {
  flex-basis: 35%;
}

.content-home>div:nth-child(2) {
  flex-basis: 55%;
}


.content-home * {
  animation: fadeInLeft 1s;
  display: block;
  text-align: justify;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0px 0px var(--app-border-radius) 0px;
  padding: 5px 20px 5px 20px;
  grid-area: nav;
  z-index: 1;
}

.social {
  grid-area: social;
  background: rgb(59, 89, 152);
  background: linear-gradient(90deg, rgba(59, 89, 152, 1) 0%, rgba(105, 135, 196, 1) 100%);
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
  z-index: 10;
}


/* ____________________________ Add-Ons ___________________________ */

.MuiLinearProgress-barColorPrimary {
  border-radius: 10px;
  background-color: #343434 !important;
}

.MuiLinearProgress-root {
  font-family: 'Source Sans Pro' !important;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  background-color: #ededed !important;
}

.MuiTooltip-tooltip {
  font-size: 1rem !important;
  background: rgba(0, 0, 0, 0.85) !important;
  backdrop-filter: blur(2px);
  text-shadow: 1px 1px 1px #000;
  padding: 10px;
}

.MuiTooltip-popper {
  text-align: center;
}

.MuiModal-root img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  max-height: 90vh;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, .25);
}

.MuiBackdrop-root {
  position: absolute;
  height: 100vh !important;
  width: 100vw !important;
  background: rgba(0, 0, 0, 0.25) !important;
  /*backdrop-filter: blur(10px);*/
}

.MuiSnackbar-root {
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
}


/* ____________________________ Mobile ___________________________ */

@media screen and (max-width: 1800px) {
  .App {
    height: 95vh;
    width: 95vw;
  }
}

@media screen and (max-width: 1200px) {

  .App,
  .content,
  .sidebar,
  .nav {
    border-radius: 0;
  }

  .App {
    height: 100vh;
    width: 100vw;
  }

  .App>* {
    zoom: 0.9;
  }

  .flex-50 * {
    flex: 0 0 100%;
    /* Substracts from margin */
    width: 0;
    margin: 0px 10px 10px 0px !important;
  }

  .column-3 {
    column-count: 2;
  }

}

/* SMARTPHONE */

@media screen and (max-width: 1000px),
(max-height: 600px) {

  body {
    height: 100%;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .App {
    grid-template-columns: 100%;
    grid-template-rows: 70px auto 45px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "sidebar"
      "content"
      "nav";
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
  }

  .App>* {
    zoom: 0.9;
  }

  .flex-50 * {
    flex: 1 0 100%;
  }

  .column-3,
  .column-2 {
    display: block;
    column-count: 2;
  }

  .content,
  .sidebar {
    height: 100%;
    overflow: visible;
    border-radius: 0px;
  }

  .content {
    min-height: 400px;
  }

  .content img.company-logo {
    display: none;
  }

  .content p {
    font-size: 1.1rem;
  }

  .content-home .typewriter {
    width: 90vw;
    white-space: normal;
    overflow: visible;
  }

  .content-home>div:nth-child(1) {
    text-align: center;
    order: 2;
  }

  .content-home>div:nth-child(2) {
    flex-basis: 100%;
    order: 1;
  }


  .sidebar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    z-index: 2;
  }


  .sidebar h1 {
    margin: 0;
  }

  .sidebar h2,
  .sidebar-section-languages,
  .sidebar-section-skills {
    display: none !important;
  }

  .sidebar-photo {
    display: none;
  }

  .sidebar-section-languages {
    display: grid;
    grid-template-columns: 0.3fr 1.7fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "flag score";
    max-width: 400px;
    margin: 0 auto;
  }

  .sidebar-section-languages:nth-child(1n) {
    grid-area: flag;
  }

  .sidebar-section-languages:nth-child(2n) {
    grid-area: score;
  }

  .sidebar-section-social {
    margin: 0;
    padding: 0;
  }

  .sidebar-section-social img {
    margin: 5px 2px;
  }

  .nav {
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(5px);
    position: sticky;
    bottom: 0;
  }

  .MuiPagination-root {
    zoom: 0.8;
  }

  .btn-primary,
  .btn-secondary {
    padding: 10px;
    width: 150px;
    zoom: 0.9;
  }

  .btn-secondary {
    display: none;
  }

  .MuiSnackbar-root>div {
    position: fixed !important;
    top: 0px !important;
    max-width: 100vw;
    margin: 12px;
    z-index: 999 !important;
  }
}

/* VERY SMALL SMARTPHONES */

@media screen and (max-width: 390px) {

  .sidebar-section-social {
    zoom: 0.9;
    margin-top: 5px;
  }

  .MuiPagination-root {
    zoom: 0.5;
  }

}